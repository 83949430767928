import React, {useEffect, useState} from 'react';
import {
  IconCloud,
  IconCloudCog,
  IconCloudRain,
  IconCloudSnow,
  IconCloudStorm,
  IconMist,
  IconSun
} from "@tabler/icons-react";

interface meteoProps {
  temperature: string | null | undefined
  weather: string | null | undefined
}

// http://www.meteo.fr/guide/guide_pictos.html
const mapIcons = [
  <IconSun key={0} />,
  <IconSun key={1} />,
  <IconSun key={2} />,
  <IconSun key={3} />,
  <IconSun key={4} />,
  <IconCloudCog key={5} />,
  <IconCloud key={6} />,
  <IconCloudCog key={7} />,
  <IconCloudRain key={8} />,
  <IconCloudRain key={9} />,
  <IconCloudSnow key={10} />,
  <IconCloudSnow key={11} />,
  <IconCloudSnow key={12} />,
  <IconCloudStorm key={13} />,
  <IconCloudStorm key={14} />,
  <IconMist key={15} />,
  <IconMist key={16} />,
  <IconMist key={17} />,
]

const MeteoWidget = () => {
  const [meteo, setMeteo] = useState<meteoProps | null>(null)

  useEffect(() => {
    const init = async () => {
      try {
        const xml = await fetch("/api/weather-forecast", {headers: {'Content-Type': 'text/xml'}}).then(res => res.text());
        const weatherForecast = new window.DOMParser().parseFromString(xml, "text/xml")
        setMeteo({
          temperature: weatherForecast.querySelector('parameter#t timerange value')?.textContent,
          weather: weatherForecast.querySelector('parameter#weather timerange value')?.textContent,
        })
      } catch (e) {
        setMeteo(null);
      }
    }
    init()
  }, []);

  const weatherIcon = (meteo?.weather && mapIcons[meteo?.weather]) ? mapIcons[meteo?.weather] : null;

  return (
    <>
      {
        meteo && (
          <div className="flex items-center gap-3">
            <div>{weatherIcon}</div>
            <span className='uppercase lg:text-[10px]'>{meteo?.temperature}°C</span>
          </div>
        )
      }
    </>
  )
};

export default MeteoWidget;
