import React from "react";
import {cn} from "@/lib/utils";

const Container = ({className, children, ...props}:React.HTMLAttributes<HTMLElement>) => {
    return (
        <div
            className={
                cn(
                    "md:container px-4 mx-auto",
                    className
                )}
            {...props}
        >
            {children}
        </div>
    );
};

export default Container;