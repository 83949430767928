import {Button} from "@/components/ui/button";
import {IconUserCircle} from "@tabler/icons-react";
import {SondageSvg, RrbLogoWhiteSvg} from "@/components/svg";
import Link from "next/link";
import {Separator} from "@/components/ui/separator";
import React, {ReactElement, useContext} from "react";
import {SocialLinkFb, SocialLinkX, SocialLinkYt} from "@/components/socialLinks/SocialLinks";
import {MenuLinksContext} from "@/components/contexts";


export function Footer(): ReactElement {
    const {menuFooterLinks} = useContext(MenuLinksContext)

    return (
        <footer className="bg-brand-mer-1000 pt-6 pb-[112px] lg:pt-12">
            <div className="container mx-auto">
                <div className="flex gap-x-8 gap-y-10 justify-between flex-col lg:flex-row">
                    <div className='flex flex-col gap-6'>
                        <div className="flex gap-6 flex-col lg:flex-row">
                            <div className='min-w-[248px]'>
                                <div className="flex flex-col gap-4 items-start md:w-[177px]">
                                    <RrbLogoWhiteSvg/>
                                    <span className="text-white">La fréquence aux couleurs de la France</span>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="flex flex-col flex-wrap gap-4 lg:max-h-[132px]">
                                    {menuFooterLinks?.length && (
                                      menuFooterLinks.map((item: any) => (
                                            <Link key={item.id} href={item.url} className='text-white text-[14px]'>
                                                {item.title}
                                            </Link>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>

                        <Separator className='bg-primary lg:hidden' />

                        <div className="flex items-center gap-2">
                            <SocialLinkFb className='rounded-full transition-all bg-brand-mer-500 hover:bg-brand-mer-200 lg:hover:bg-brand-mer-500 lg:bg-neutral-400 w-11 h-11 lg:w-8 lg:h-8 grid place-items-center' iconClassName='w-5 lg:w-4' iconColor='var(--brand-mer-1000)'  />
                            <SocialLinkX className='rounded-full transition-all bg-brand-mer-500 hover:bg-brand-mer-200 lg:hover:bg-brand-mer-500 lg:bg-neutral-400 w-11 h-11 lg:w-8 lg:h-8 grid place-items-center' iconClassName='w-5 lg:w-4' iconColor='var(--brand-mer-1000)'  />
                            <SocialLinkYt className='rounded-full transition-all bg-brand-mer-500 hover:bg-brand-mer-200 lg:hover:bg-brand-mer-500 lg:bg-neutral-400 w-11 h-11 lg:w-8 lg:h-8 grid place-items-center' iconClassName='w-5 lg:w-4' iconColor='var(--brand-mer-1000)'  />
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 md:min-w-[324px]">
                        {/*<div className="p-4 bg-brand-mer-900 flex flex-col gap-4">*/}
                        {/*    <Button variant="secondary" iconLeft={<IconUserCircle size="18"/>}>Se connecter</Button>*/}
                        {/*    <Button iconLeft={<SondageSvg size="18"/>}>Mon avis</Button>*/}
                        {/*</div>*/}
                        {/*<Button>S&apos;abonner à la newsletter</Button>*/}
                    </div>
                </div>
            </div>
        </footer>
    )
}
