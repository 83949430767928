import {PreviewAlert} from "components/preview-alert"
import {Ads} from "@/components/ui/ads";
import {AudioControls} from "@/components/audioPlayer";
import {Toaster} from "@/components/ui/toaster";
import Container from "@/components/ui/container";
import {useEffect} from "react";
import {reloadRevive} from "@/lib/utils";
import {Footer, Header, Preheader} from "@/components/regions";
import { GoogleAnalytics } from '@next/third-parties/google'

export const runtime = 'edge'

export function Layout({children}) {

  useEffect(() => {
    reloadRevive();
  }, []);

    return (
      <>
        <PreviewAlert/>
        <header className="sticky z-50 top-0">
          <Preheader/>
          <Header/>
        </header>
        <Container>
          <div className='py-8 w-fit mx-auto'>
            <Ads adsId='53' dimension={'728090'} className='ads-wrapper' />
          </div>
        </Container>

        <main>{children}</main>

        <Footer/>
        <div className='ici'>
          <AudioControls/>
        </div>
        <GoogleAnalytics gaId="G-NTRJ5B3KBS" />
        <Toaster/>
      </>
    )
}
