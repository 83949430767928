"use client"

import {cn} from "@/lib/utils";
import {cva, type VariantProps} from "class-variance-authority";
import * as React from "react";
import {ReactElement} from "react";
import {RrbLogoSvg} from "@/components/svg";

const adsExampleVariants = cva(
    'bg-muted grid place-items-center',
    {
        variants: {
            dimension: {
                '728090': "w-[728px] h-[90px]",
                '300600': "w-[300px] h-[600px]",
                '300250': "w-[300px] h-[250px]",
                '320050': "w-[320px] h-[50px]",
            }
        },
        defaultVariants: {
            dimension: "728090",
        },
    }
);

export interface AdsProps extends React.HTMLAttributes<HTMLElement>,
  VariantProps<typeof adsExampleVariants> {
    adsId: string,
}

const Ads = ({className, dimension, adsId = '', ...props}: AdsProps): ReactElement => (
  <ins data-revive-zoneid={adsId} data-revive-id={process.env.NEXT_PUBLIC_REVIVE_ID} className={
    cn(
      "inline-block",
      className
    )}
       {...props}>
    <div className={adsExampleVariants({dimension})}>
      <RrbLogoSvg size={'40'}/>
    </div>
  </ins>
)

export {Ads, adsExampleVariants}
