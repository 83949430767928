import React from 'react';
import Link from "next/link";
import {FbLogoSvg,XLogoSvg,YtLogoSvg} from "@/components/svg";

export interface SocialLinkProps extends React.HTMLAttributes<HTMLElement>{
    iconClassName?: string | undefined,
    iconColor?: string | undefined,
    url?: string | undefined
}

const SocialLinkFb = ({iconClassName, iconColor, url, ...props}: SocialLinkProps) => {
    return (<Link href={ url ? url : 'https://fr-fr.facebook.com/RadioRythmeBleu/' } target='_blank' {...props}><FbLogoSvg className={iconClassName} color={iconColor} /></Link>);
};
const SocialLinkX = ({iconClassName, iconColor, url, ...props}: SocialLinkProps) => {
    return (<Link href={ url ? url : 'https://twitter.com/RRB_NC' } target='_blank' {...props}><XLogoSvg className={iconClassName} color={iconColor} /></Link>);
};
const SocialLinkYt = ({iconClassName, iconColor, url, ...props}: SocialLinkProps) => {
    return (<Link href={ url ? url : 'https://www.youtube.com/channel/UCpJBjs9gx9go13oJrXW4bzQ' } target='_blank' {...props}><YtLogoSvg className={iconClassName} color={iconColor} /></Link>);
};

export {SocialLinkFb, SocialLinkX, SocialLinkYt};