import Link from "next/link";
import {Separator} from "@/components/ui/separator";
import {ReactElement, useContext} from "react";
import {MeteoWidget} from "@/components/meteo";
import {SocialLinkFb, SocialLinkX, SocialLinkYt} from "@/components/socialLinks/SocialLinks";
import {MenuLinksContext} from "@/components/contexts";
import {buttonVariants} from "@/components/ui/button";

export function Preheader(): ReactElement {

  const {menuHeaderLinks} = useContext(MenuLinksContext)

  return (
    <div className='bg-light text-light-foreground py-1 px-8 justify-end hidden lg:flex'>
      <div className="flex items-center gap-6">
        {menuHeaderLinks?.length && (
          menuHeaderLinks.map((item) => (
            <Link key={item.id} href={item.url} className={(item.id === "menu_link_content:cfc800b3-a88e-4af6-beda-bbf9a8adaa07" && (buttonVariants({size:"preheader"}))) + ' uppercase text-[10px]'}>
              {item.title}
            </Link>
          ))
        )}
        <MeteoWidget/>
        <Separator orientation="vertical"/>
        <div className="flex items-center gap-3">
          <SocialLinkFb/>
          <SocialLinkX/>
          <SocialLinkYt/>
        </div>
      </div>
    </div>
  )
}
